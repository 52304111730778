import sch from "@stanfordchildrens/sch-client/src/ts/core"

import "../scss/cds"

import "../img/icon-192.png"
import "../img/icon-512.png"
import "../img/icon-tpn.png"

sch.config({
   application : "Clinical Decision Support",
   pwa         : true,
   env         : location.hostname.includes("cdsdev") || location.hostname == "localhost" ? "dev" : "prd"
});